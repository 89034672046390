@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

.custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .tablepage{
     
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        /* justify-content: flex-start; */
  }

  .innertabcont{
      margin-top: 40px;
      width: 80%;
      height: 100%;
      margin-bottom: 70px;
  }

  .datepicker{
    display: flex;
    justify-content: flex-start;
    width: 100%;

  }

  .infodivs{
    /* height: 150px; */
    margin-bottom: 20px;
  }

  .placementinfocard{
    display: flex;
    flex-direction: column;
  }

  .infodivs{
    display: flex;
    width: 100%;
    /* background-color: antiquewhite; */
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
  }


  .titleinfo{
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    color: blueviolet;
  }

  .titlenumber{
    font-size: 60px;
    font-family: 'Fjalla One', sans-serif;
  }

  .footerfortable{
    height: 40px;
    width: 100%;
    background-color: black;
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-family: 'Rubik', sans-serif;

  }
  @media only screen and (max-width: 768px)
  {
    .innertabcont{
        margin-top: 10px;
        width: 100%;
    }

    .titleinfo{
      font-size: 16px;
      font-family: 'Rubik', sans-serif;
      color: blueviolet;
    }
  
    .titlenumber{
      font-size: 50px;
      font-family: 'Fjalla One', sans-serif;
    }

  }